import React from 'react'
import Upcoming from './Components/Upcoming';
function App() {
  return (

    <>
      <Upcoming />
    </>
  );
}

export default App;
