import React from 'react'
import 'animate.css';
import twitterIcon from "../Assets/icons/twitter.png"
import telegramIcon from "../Assets/icons/telegram.png"
const Upcoming = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className="flex  bg-upcoming  bg-cover bg-center bg-no-repeat   h-screen  bg-gray-900 overflow-hidden " >
      <div className='w-full mt-5 sm:mt-12 mb-20 flex flex-col justify-between font-bold text-gray-700  font-Archivo'>
        <div className='flex '>
          <div className='space-y-1  flex flex-col'>
            <p className='animate__animated animate__fadeIn p-1 text-center font-extrabold   text-4xl sm:text-7xl '>Zamiata</p>
            <p className='animate__animated animate__fadeInLeft animate__faster bg-orange-600 p-1 text-xl sm:text-3xl text-white' >Play-to-earn metaverse</p>
            <p className='animate__animated animate__fadeInLeft bg-orange-600 p-1 text-xl sm:text-3xl text-white' > NFTs GameFi</p>
            <div className=' flex flex-col justify-center items-center'>
              <p className=' px-1 font-Archivo text-xs sm:text-sm font-medium'>Keep checking our social media outlets for updates.</p>
              <div className="mt-1 cursor-pointer space-x-5  text-gray-800 flex ">
                <div
                  onClick={() => openInNewTab('https://twitter.com/ZamiataOfficial')}
                  className="  duration-300 flex flex-col justify-center items-center text-blue-400 hover:text-blue-500">
                  <img className="w-8 sm:w-10"
                    src={twitterIcon} alt="twitter" />
                  <p className="font-Inter text-xs font-bold text-gray-800">Twitter</p>
                </div>
                <div
                  onClick={() => openInNewTab('https://t.me/zamiata')}
                  className=" duration-300 items-center flex flex-col justify-center   text-blue-700 hover:text-blue-800">
                  <img className=" w-8 sm:w-10"
                    src={telegramIcon} alt="telegram" />
                  <p className="font-Inter text-xs font-bold text-gray-800">Telegram</p>
                </div>


              </div>
            </div>
          </div>

        </div>

        <div>
          <p className='animate__animated animate__bounceIn text-center text-xl sm:text-2xl text-orange-800 opacity-80'>COMING SOON</p>
        </div>
      </div>

    </div>

  )
}

export default Upcoming